import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";


gsap.registerPlugin(ScrollTrigger, ScrollSmoother);



window.addEventListener('load', () => {
  const applyScrollAnimations = () => {
    if (window.innerWidth > 1024) {
      const scrollingList = document.getElementById('scrollingList');
      const scrollingImages = document.getElementById('scrollingImages');
      const scrollingListSection = document.getElementById('scrolling-list');
      const scrollingListItems = document.querySelectorAll('.scrolling-list__list li');

      gsap.to(scrollingListSection, {
        scrollTrigger: {
          trigger: scrollingListSection,
          start: 'top top',
          end: '100% top',
          pin: true,
          scrub: 0,
          pinSpacing: true,
          ease: 'none',
          onUpdate: ScrollTrigger.update,
        },
        duration: 1,
      });

      gsap.to(scrollingList, {
        y: '-50%',
        duration: 3,
        scrollTrigger: {
          trigger: scrollingListSection,
          start: "top top",
          end: "200% top",
          scrub: 2,
        },
      });

      gsap.to(scrollingImages, {
        y: '-70%',
        duration: 2,
        scrollTrigger: {
          trigger: scrollingListSection,
          start: "top top",
          end: "200% top",
          scrub: 2,
        },
      });

      scrollingListItems.forEach((line) => {
        gsap.to(line, {
          backgroundPositionX: 0,
          ease: "none",
          duration: 2,
          scrollTrigger: {
            trigger: line,
            scrub: 2,
            start: "top 60%",
            end: "bottom 60%",
          },
        });
      });
    }
  };

  applyScrollAnimations();

  window.addEventListener('resize', () => {
    // Повторно застосовуємо анімації при зміні розміру вікна
    ScrollTrigger.refresh();
    applyScrollAnimations();
  });
});
